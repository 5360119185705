const loadGTMScript = async (tag: string) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtm.js?id=${tag}`;
    script.async = true;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    dataLayer: Record<string, any>[];
    oneGa: Record<string, any>[];
    pagData: any;
    trackingLayer: any;
  }
}

export const initializeAnalytics = async (_env: string, acctId?: string) => {
  if (typeof window === "undefined") {
    return;
  }
  const initEvent = { "gtm.start": Date.now(), event: "gtm.js" };

  window.dataLayer = [initEvent];
  window.oneGa = [initEvent];
  window.trackingLayer = [];
  window.pagData = [];

  // const tagMapping = {
  //   K: 'GTM-NZHDPTS&gtm_auth=uDxPbskMf_MBJoavAtmlGA&gtm_preview=env-4&gtm_cookies_win=x',
  //   PP: 'GTM-NZHDPTS&gtm_auth=B33RyIMrmvXRoP352_0zkw&gtm_preview=env-3&gtm_cookies_win=x',
  //   P: 'GTM-NZHDPTS'
  // }

  if (typeof jest === "undefined") {
    // @ts-ignore
    // await loadGTMScript(tagMapping[env] as string)
    await loadGTMScript(acctId ?? "GTM-W2NKNJZ&l=oneGa");
  }
};
