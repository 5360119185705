export const envs = {
  localhost: {
    apiBaseUrl: "http://localhost:8080",
    pfsAppBaseUrls: {
      us: "https://ac-test00.autolendingapps.com/pfs/",
      ca: "https://ac-test00.autolendingapps.com/pfsc/",
    },
    oneGaEnv: "K",
  },
  "pfs-tile.fs-contract-management.porsche-preview.com": {
    apiBaseUrl:
      "https://pfs-tile.fs-contract-management.porsche-preview.com/api",
    pfsAppBaseUrls: {
      us: "https://ac-test00.autolendingapps.com/pfs/",
      ca: "https://ac-test00.autolendingapps.com/pfsc/",
    },
    oneGaEnv: "PP",
  },
  "pfs-tile.fs-contract-management.porsche.com": {
    apiBaseUrl: "https://pfs-tile.fs-contract-management.porsche.com/api",
    pfsAppBaseUrls: {
      us: "https://pfsaccount.porscheusa.com/",
      ca: "https://pfscaccount.porsche.ca/",
    },
    oneGaEnv: "P",
  },
};

export function getEnv() {
  return envs[window.location.hostname];
}
