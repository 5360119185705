import {
  PButtonGroup,
  PIcon,
  PLink,
  PText,
} from "@porsche-design-system/components-react";
import { gridBasic } from "@porsche-design-system/components-react/styles";
import {
  buttonClick,
  BUTTONS,
  createEventName,
} from "../onega/AnalyticsEventTemplates";
import { trackClickEvent } from "@legacy/one-ga";
import styled from "styled-components";

const ButtonPanel = ({
  numberOfAccounts,
  applicationStatus,
  pfsAppBaseUrl,
  context,
  translations,
}) => {
  const trackClick = (button: BUTTONS) => () =>
    trackClickEvent(
      buttonClick(button, createEventName(numberOfAccounts, applicationStatus)),
    );
  const leaseEndParams =
    context.country === "us"
      ? "usa"
      : context.locale === "en_US"
        ? "canada/en/accessoriesandservices"
        : "canada/fr/accessoriesandservice";
  const manageAccountEndpoint =
    context.country === "us" ? "accountSelection" : "accountInfo/accountDetail";

  const ButtonPanelGrid = styled.div({
    ...gridBasic,
  });
  const IconLinkGrid = styled.div({
    ...gridBasic,
  });
  const IconLink = styled.a({
    display: "inline",
    marginRight: "20px",
  });

  return (
    <ButtonPanelGrid className="ButtonPanel">
      <PButtonGroup direction={{ base: "column", s: "row" }}>
        {!numberOfAccounts && (
          <PLink
            target={"_blank"}
            variant="primary"
            href={`${pfsAppBaseUrl}AutoService/accountEnrollment`}
            onClick={trackClick(BUTTONS.LINK_ACCOUNT)}
            data-testid="account-enrollment"
          >
            {translations.Link_My_Account_BUTTON}
          </PLink>
        )}
        {numberOfAccounts > 0 && (
          <>
            <PLink
              target={"_blank"}
              variant="primary"
              href={`${pfsAppBaseUrl}AutoService/${manageAccountEndpoint}`}
              onClick={trackClick(BUTTONS.MANAGE)}
              data-testid="account-management"
            >
              {translations.Manage_Account_BUTTON}
            </PLink>
            {context.country === "us" && (
              <PLink
                variant="secondary"
                target={"_blank"}
                href={`${pfsAppBaseUrl}AutoService/accountInfo/oneTimePayment`}
                onClick={trackClick(BUTTONS.MAKE_PAYMENTS)}
                data-testid="make-payment"
              >
                {translations.Make_Payments_BUTTON}
              </PLink>
            )}

            <PLink
              variant="secondary"
              target={"_blank"}
              href={`https://www.porsche.com/${leaseEndParams}/porschefinancialservices/lease-end/`}
              onClick={trackClick(BUTTONS.LEASE_END)}
              data-testid="lease-end"
            >
              {translations.Lease_BUTTON}
            </PLink>
          </>
        )}
      </PButtonGroup>
      <IconLinkGrid>
        {!numberOfAccounts && context.country === "us" && (
          <IconLink
            target="_blank"
            href={`${pfsAppBaseUrl}howToVideos`}
            onClick={trackClick(BUTTONS.HOWTO)}
          >
            <PIcon name="video" style={{ padding: "0px 4px 0px 4px" }} />
            <PText style={{ display: "inline-block" }}>
              {translations.Watch_How_To_Videos_BUTTON}
            </PText>
          </IconLink>
        )}
        <IconLink
          target="_blank"
          href={`${pfsAppBaseUrl}AutoService/helpCenterFaqs`}
          onClick={trackClick(BUTTONS.FAQ)}
        >
          <PIcon name="question" style={{ padding: "0px 4px 0px 4px" }} />
          <PText style={{ display: "inline-block" }}>
            {translations.FAQ_BUTTON}
          </PText>
        </IconLink>
        <IconLink
          target="_blank"
          href={`${pfsAppBaseUrl}AutoService/helpCenterContact`}
          onClick={trackClick(BUTTONS.CONTACT)}
        >
          <PIcon name="email" style={{ padding: "0px 4px 0px 4px" }} />
          <PText style={{ display: "inline-block" }}>
            {translations.Contact_Us_BUTTON}
          </PText>
        </IconLink>
      </IconLinkGrid>
    </ButtonPanelGrid>
  );
};

export default ButtonPanel;
