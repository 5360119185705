import { IAnalyticsEventTemplate } from "./AnalyticsEventTemplates";
import { IAnalyticsEvent, IComponentForm } from "./IAnalyticsEvent";
import {
  isLoggedIn,
  getEnvironment,
  getLanguage,
  getMarketplace,
  getCarPayload,
  getPartner,
  getFinancialPayload,
  getProcess,
  getServiceProduct,
  getUserType,
} from "./analyticsState";

// for testing
export let windowInstance: Window;

// window global may not exist during import with next.js
if (typeof window !== "undefined") {
  windowInstance = window ?? {};
}

export const createAnalyticsEvent = (
  template: IAnalyticsEventTemplate,
  applicationId: string,
  componentForm?: IComponentForm,
): IAnalyticsEvent => {
  if (typeof windowInstance === "undefined") {
    throw new Error("calling createAnalyticsEvent in server context");
  }

  if (!template) {
    throw new Error("Event template is undefined");
  }

  const deviceBrowserOrientation =
    windowInstance?.innerWidth > windowInstance?.innerHeight ? "l" : "p";
  const country = getMarketplace();
  const car = getCarPayload();
  const partner = getPartner();
  const financialProduct = getFinancialPayload();
  const process = getProcess();
  const serviceProduct = getServiceProduct();
  const userType = getUserType();

  return {
    context: {
      eventAction: template.action,
      applicationId,
      language: getLanguage(),
      country: country.toUpperCase(),
      environment: getEnvironment(),
      timestamp: new Date().toISOString(),
      currency: country === "us" ? "USD" : "CAD",
      server: windowInstance?.location?.hostname,
    },
    visitor: {
      loginStatus: isLoggedIn(),
      deviceBrowserHeight: windowInstance?.innerHeight.toString(),
      deviceBrowserWidth: windowInstance?.innerWidth.toString(),
      deviceBrowserOrientation,
      useragent: windowInstance?.navigator.userAgent,
      ...(userType && { userType }),
    },
    pageExperience: {
      pageId: windowInstance?.location?.href,
      pageName: template.page,
    },
    ...(car && { car }),
    ...(partner && { partner }),
    ...(financialProduct && { financialProduct }),
    ...(componentForm && { componentForm }),
    ...(process && { process }),
    ...(serviceProduct && { serviceProduct }),
  };
};
