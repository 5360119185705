import "./App.scss";
import { useEffect, useState } from "react";
import Account from "./components/Account";
import NoAccount from "./components/NoAccount";
import { PHeading } from "@porsche-design-system/components-react";
import data from "./locale/data.json";
import ButtonPanel from "./components/ButtonPanel";
import CreditApplication from "./components/CreditApplication";
import { getEnv } from "./env";
import styled from "styled-components";

export interface IContext {
  accessToken: string;
  ciamId: string;
  country: string;
  locale: string;
}

function App({ context, onReady }) {
  const { apiBaseUrl, pfsAppBaseUrls } = getEnv();

  const pfsAppBaseUrl = pfsAppBaseUrls[context.country];
  const translations = data[context.country][context.locale];

  const [result, setResult] = useState({} as any);
  useEffect(() => {
    void fetch(`${apiBaseUrl}/info`, {
      headers: {
        Authorization: `Bearer ${context.accessToken}`,
        Country: context.country,
      },
    })
      .then(async (res) => res.json())
      .then((data) => {
        setResult(data);
        onReady();
      });
  }, [apiBaseUrl, context.ciamId, context.country, onReady]);

  let hasAccount = false;
  let numberOfAccounts = 0;
  const applicationStatus: boolean = result.status;
  if (result) {
    const accountsArrayLength = result.info?.length;
    if (accountsArrayLength > 0) {
      hasAccount = true;
      numberOfAccounts = accountsArrayLength;
    }
  }

  const accountList = result.info;

  const PfsWrapper = styled.div({});
  return (
    <div>
      {!hasAccount && (
        <PHeading
          tag="h3"
          size="large"
          className="tile-heading"
          data-testid="no-account"
        >
          {translations.GENERAL_HEADER_TEXT}
        </PHeading>
      )}
      {hasAccount && (
        <PHeading
          tag="h3"
          size="large"
          className="tile-heading"
          data-testid="account"
        >
          {translations.ACCOUNT_MANAGEMENT_TEXT}
        </PHeading>
      )}
      <PfsWrapper className="pfs-wrapper">
        {applicationStatus && context.country !== "ca" && (
          <CreditApplication translations={translations} />
        )}{" "}
        <div className="account-wrapper">
          {!hasAccount && (
            <NoAccount context={context} translations={translations} />
          )}
          {hasAccount && (
            <Account
              accounts={accountList}
              context={context}
              translations={translations}
            />
          )}
        </div>
      </PfsWrapper>
      <ButtonPanel
        numberOfAccounts={numberOfAccounts}
        applicationStatus={applicationStatus}
        pfsAppBaseUrl={pfsAppBaseUrl}
        context={context}
        translations={translations}
      />
    </div>
  );
}

export default App;
