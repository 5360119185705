import { IAnalyticsClickEventTemplate } from "@legacy/one-ga";

export enum BUTTONS {
  FAQ = "FAQs",
  HOWTO = "Watch How-To Videos",
  LINK_ACCOUNT = "Link My Account",
  CONTACT = "Contact Us",
  MANAGE = "Manage Account",
  LEASE_END = "Lease-end Options",
  MAKE_PAYMENTS = "Make Payments",
}

export const buttonClick: (
  button: BUTTONS,
  action: string,
) => IAnalyticsClickEventTemplate = (button, action) => ({
  action,
  clickElementId: "call_to_action",
  clickElementName: button,
  clickElementType: "button",
  page: "home",
});

export const createEventName = (numberOfAccounts, applicationStatus) => {
  const accPart =
    numberOfAccounts > 1
      ? "MultipleAccounts"
      : numberOfAccounts === 1
        ? "Account"
        : "NoAccount";
  const statusPart = applicationStatus ? "Credit" : "";
  return `PAGMyPorsche_PFSTile${accPart}${statusPart}_Click`;
};
