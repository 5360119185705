import {
  IAnalyticsCarProps,
  IAnalyticsFinancialProductProps,
  IAnalyticsPartner,
  IAnalyticsProcess,
  IAnalyticsServiceProduct,
  IComponentForm,
} from "./IAnalyticsEvent";

import { VehicleView } from "@legacy/external-models";

let loggedIn = false;
let marketplace = "us";
let language = "en";
let application = "carsales_checkout";
let environment: "K" | "PP" | "P";

let vehicleId: string;

let financialData: IAnalyticsFinancialProductProps;
let car: IAnalyticsCarProps;
let componentForm: IComponentForm | undefined;

let partner: IAnalyticsPartner;

let currentPage = "";

let process: IAnalyticsProcess | undefined;

let serviceProduct: IAnalyticsServiceProduct | undefined;

let userType: string | undefined;

const FinProductDefaults = {
  downpaymentValue: undefined,
  productCategory: undefined,
  productName: undefined,
  paymentFinal: undefined,
  mileageUnit: "miles",
};

export function isLoggedIn() {
  return loggedIn;
}

export function setLoggedIn(value: boolean) {
  loggedIn = value;
}

export function getMarketplace() {
  return marketplace;
}

export function getVehicleId() {
  return vehicleId;
}

export function setVehicleId(value: string) {
  vehicleId = value;
}

export function setMarketplace(value: string) {
  marketplace = value;
}

export function setPartner(value: IAnalyticsPartner) {
  partner = value;
}

export function setFinancialPayload(value: IAnalyticsFinancialProductProps) {
  financialData = value;
}

export function getFinancialPayload(): IAnalyticsFinancialProductProps {
  return financialData;
}

export function setVehiclePrice(price: number) {
  setFinancialPayload({
    ...(getFinancialPayload() ?? FinProductDefaults),
    cashPrice: price,
  });
}

export function setFinProduct(product: string) {
  setFinancialPayload({
    ...(getFinancialPayload() ?? FinProductDefaults),
    productCategory: product,
  });
}

export function setAdditionalProducts(plans: { code: string }[] | undefined) {
  setFinancialPayload({
    ...getFinancialPayload(),
    additionalProducts: plans?.length
      ? plans.map((product) => ({ OptionName: product.code }))
      : [{ OptionName: "none" }],
  });
}

export function setCarPayload(value: IAnalyticsCarProps): void {
  car = value;
}

export function getLanguage() {
  return language;
}

export function getPartner() {
  return partner;
}

export function getApplication() {
  return application;
}

export function setLanguage(localeOrLanguage: string) {
  // Locale
  if (localeOrLanguage && localeOrLanguage.length === 5) {
    if (localeOrLanguage.includes("_") || localeOrLanguage.includes("-")) {
      language = localeOrLanguage.replace("_", "-").split("-")[0];
    } else {
      language = "en";
    }
  }
  // Language
  else if (localeOrLanguage && localeOrLanguage.length === 2) {
    language = localeOrLanguage;
  }
  // Default language
  else {
    language = "en";
  }
}

export type AnalyticsApp =
  | "carsales_checkout"
  | "carsales_calculator"
  | "myporsche_core"
  | "myporsche_dwk"
  | "myporsche_rsa"
  | "onesales"
  | "myporsche_kbb"
  | "myporsche_pcc"
  | "myporsche_promotions"
  | "myporsche_passport";

export function setApplication(value: AnalyticsApp) {
  application = value;
}

export function setCurrentPage(page: string) {
  currentPage = page;
}

export function getCurrentPage(): string {
  return currentPage;
}

export function getEnvironment() {
  return environment;
}

export function setEnvironment(value: "K" | "PP" | "P") {
  environment = value;
}

export function getCarPayload(): IAnalyticsCarProps {
  return car;
}

export function setComponentForm(value: IComponentForm | undefined) {
  componentForm = value;
}

export function getComponentForm(): IComponentForm {
  return componentForm!;
}

export function carAnalytics(
  value: VehicleView,
  listingId: string,
): IAnalyticsCarProps {
  const realcarStatus = value.conditionType === "NEW" ? "new_car" : "used_car";

  return {
    modelName: value.modelName,
    modelCode: value.modelCode,
    offerKey: value.key,
    realcarStatus,
    vin: value.vin,
    type: "real_car",
    listingId,
  };
}

export function setProcess(value: IAnalyticsProcess) {
  process = value;
}

export function getProcess() {
  return process;
}

export function setServiceProduct(value: IAnalyticsServiceProduct) {
  serviceProduct = value;
}

export function getServiceProduct() {
  return serviceProduct;
}

export function setUserType(value: string) {
  userType = value;
}

export function getUserType() {
  return userType;
}
