import { PTag, PIcon } from "@porsche-design-system/components-react";

const CreditApplication = ({ translations }) => {
  return (
    <div className="application-submitted" data-testid="application-submitted">
      <PTag color="notification-success-soft">
        <PIcon
          name="success-filled"
          color="notification-success"
          style={{ marginTop: "-1px" }}
        />
        {translations.APPLICATION_SUBMITTED_TEXT}
      </PTag>
    </div>
  );
};

export default CreditApplication;
