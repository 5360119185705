import { PText } from "@porsche-design-system/components-react";

const NoAccount = ({ context, translations }) => {
  return (
    <div>
      <PText size="small" className="bottom-text" data-testid="no-account-text">
        {translations.NO_ACCOUNT_TEXT}
      </PText>
      {context.country === "us" && (
        <PText
          size="small"
          className="bottom-text"
          data-testid="assistance-text"
        >
          {translations.ASSISTANCE_TEXT}
        </PText>
      )}
    </div>
  );
};

export default NoAccount;
