import {
  initializeAnalytics,
  setApplication,
  setEnvironment,
  setLanguage,
  setLoggedIn,
  setMarketplace,
} from "@legacy/one-ga";
import { getEnv } from "../env";

export function configureAnalytics(context) {
  const { oneGaEnv } = getEnv();

  setApplication("myporsche_core");
  setLoggedIn(true);
  setMarketplace(context.country);
  setLanguage(context.locale);
  setEnvironment(oneGaEnv);

  void initializeAnalytics(oneGaEnv);
}
