import { PCarousel, PText } from "@porsche-design-system/components-react";
import {
  spacingFluidMedium,
  borderRadiusMedium,
  themeLightBackgroundBase,
} from "@porsche-design-system/components-react/styles";
import dayjs from "dayjs";
import "dayjs/locale/fr-ca";
import styled from "styled-components";

const Account = ({ accounts, context, translations }) => {
  const format = context.locale === "fr_FR" ? "D MMMM YYYY" : "MMMM D, YYYY";
  const dayjsLocale = context.locale === "fr_FR" ? "fr-ca" : "en";
  const formatDate = (dt, format) =>
    dayjs(dt).locale(dayjsLocale).format(format);
  const CarouselItem = styled.div({
    width: "460px",
    backgroundColor: themeLightBackgroundBase,
    height: "hug(350px)",
    borderRadius: borderRadiusMedium,
    padding: spacingFluidMedium,
  });
  const SingleAccountWrapper = styled.div({
    paddingTop: "1.5rem",
  });
  return (
    <>
      <div className="slider-content">
        {accounts.length === 1 && (
          <SingleAccountWrapper data-testid={"single-account-wrapper"}>
            <PText align="left" className="account-label">
              {context.locale === "fr_FR" ? "Compte" : "Account"}
            </PText>
            <PText align="left" className="account-detail">
              {accounts[0].AccountNumber}
            </PText>
            {accounts[0].PaymentDueDate && (
              <>
                <PText align="left" className="account-label">
                  {translations.NEXT_PAYMENT}
                </PText>
                <PText align="left" className="account-detail">
                  {formatDate(accounts[0].PaymentDueDate, format)}
                </PText>
              </>
            )}
            {accounts[0].LastStatementDate && (
              <>
                <PText align="left" className="account-label">
                  {translations.CONTRACT_END}
                </PText>
                <PText align="left" className="account-detail">
                  {formatDate(
                    dayjs()
                      .locale(dayjsLocale)
                      .add(accounts[0].DaysToMaturity, "d"),
                    format,
                  )}
                </PText>
              </>
            )}
          </SingleAccountWrapper>
        )}
        {accounts.length > 1 && (
          <PCarousel slidesPerPage={"auto"} width="basic" alignHeader="left">
            {accounts.map((acc, index) => (
              <CarouselItem key={index} className="text-content">
                <PText
                  align="left"
                  className="account-label"
                  data-testid="account-label"
                >
                  {context.locale === "fr_FR" ? "Compte" : "Account"}
                </PText>
                <PText align="left" className="account-detail">
                  {acc.AccountNumber}
                </PText>
                {acc.PaymentDueDate && (
                  <>
                    <PText align="left" className="account-label">
                      {translations.NEXT_PAYMENT}
                    </PText>
                    <PText align="left" className="account-detail">
                      {formatDate(acc.PaymentDueDate, format)}
                    </PText>
                  </>
                )}
                {acc.LastStatementDate && (
                  <>
                    <PText align="left" className="account-label">
                      {translations.CONTRACT_END}
                    </PText>
                    <PText align="left" className="account-detail">
                      {formatDate(
                        dayjs()
                          .locale(dayjsLocale)
                          .add(acc.DaysToMaturity, "d"),
                        format,
                      )}
                    </PText>
                  </>
                )}
              </CarouselItem>
            ))}
          </PCarousel>
        )}
      </div>
      <PText size="small" className="bottom-text">
        {translations.SELECT_OPTION_TEXT}
      </PText>
    </>
  );
};

export default Account;
