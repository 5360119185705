import { useCallback } from "react";
import { IAnalyticsPageLoadEventTemplate } from "./AnalyticsEventTemplates";
import {
  getApplication,
  getComponentForm,
  setCurrentPage,
} from "./analyticsState";
import { trackEvent } from "./trackEvent";
import { createAnalyticsEvent } from "./createAnalyticsEvent";

export const trackPageLoadEvent = (
  event: IAnalyticsPageLoadEventTemplate,
  componentForm?: any,
  applicationId?: any,
) => {
  try {
    trackEvent(
      createAnalyticsEvent(
        event,
        applicationId ?? getApplication(),
        componentForm ?? getComponentForm(),
      ),
    );

    setCurrentPage(event.page);
  } catch (e: any) {
    console.log("Failed to send analytics event", e);
  }
};

export const usePageLoadAnalytics = (
  event: IAnalyticsPageLoadEventTemplate,
) => {
  return useCallback(() => trackPageLoadEvent(event), [event]);
  // data is not included in dependencies because the object reference might change with each render,
  // but we don't want to create another analytics event
};
