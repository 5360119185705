import {
  ElementType,
  IAnalyticsClickEventTemplate,
} from "./AnalyticsEventTemplates";
import { IAnalyticsEvent, IComponentForm } from "./IAnalyticsEvent";
import { createAnalyticsEvent } from "./createAnalyticsEvent";

export interface IAnalyticsClickPayload {
  clickElementId: string | undefined;
  targetUrl?: string;
}

export interface IAnalyticsClickEvent extends IAnalyticsEvent {
  componentClick: {
    clickElementType: ElementType;
    clickElementId: string | undefined;
    clickElementName: string;
    targetUrl?: string;
  };
}

export const createAnalyticsClickEvent = (
  template: IAnalyticsClickEventTemplate,
  applicationId: string,
  clickPayload: IAnalyticsClickPayload,
  componentForm?: IComponentForm,
): IAnalyticsClickEvent => {
  return {
    ...createAnalyticsEvent(template, applicationId, componentForm),
    componentClick: {
      clickElementId: clickPayload.clickElementId ?? template.clickElementId,
      clickElementName: template.clickElementName,
      clickElementType: template.clickElementType,
      targetUrl: clickPayload.targetUrl,
    },
  };
};
