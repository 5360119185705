import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App, { IContext } from "./App";
import { porscheContentAreaCommunicator } from "@myporsche/content-area-communicator";
import { PorscheDesignSystemProvider } from "@porsche-design-system/components-react";
import { configureAnalytics } from "./onega";

let context: IContext | null = null;

const renderApp = (context: IContext) => {
  return ReactDOM.render(
    <React.StrictMode>
      <PorscheDesignSystemProvider>
        {context.ciamId && (
          <App
            context={context}
            onReady={() => {
              porscheContentAreaCommunicator.contentAreaReady();
              configureAnalytics(context);
            }}
          />
        )}
      </PorscheDesignSystemProvider>
    </React.StrictMode>,
    document.getElementById("root"),
  );
};

const onMessage = (event: any) => {
  if (event.ciamId && !context) {
    context = {
      accessToken: event.accessToken,
      ciamId: event.ciamId,
      country: event.country,
      locale: event.language,
    };
    renderApp(context);
  }
};

const DEFAULT_TEST_USER_ID = "qfq1mj1azjff4yle";

// we're not in iframe - so it must be our test environment
if (window.location === window.parent.location) {
  // pretend to receive an event from MyPorsche
  const context = {
    accessToken: "",
    ciamId: "",
    country: "us",
    locale: "en_US",
  };
  const searchParams = new URLSearchParams(window.location.search);
  context.ciamId = searchParams.get("ciamId") ?? DEFAULT_TEST_USER_ID;
  context.accessToken = searchParams.get("accessToken");
  renderApp(context);
}

porscheContentAreaCommunicator.contentAreaInitialized();
// listen for an event
porscheContentAreaCommunicator.onContentAreaMessage(onMessage);
