import { IAnalyticsClickEventTemplate } from "./AnalyticsEventTemplates";
import { createAnalyticsClickEvent } from "./createAnalyticsClickEvent";
import { useCallback } from "react";
import {
  AnalyticsApp,
  getApplication,
  getComponentForm,
} from "./analyticsState";
import { trackEvent } from "./trackEvent";
import { IComponentForm } from "./IAnalyticsEvent";

export interface ITrackClickEventsParams {
  clickElementId?: string;
  targetUrl?: string;
  componentForm?: IComponentForm;
  application?: AnalyticsApp;
}

export const trackClickEvent = (
  event: IAnalyticsClickEventTemplate,
  params?: ITrackClickEventsParams,
) => {
  const { clickElementId, targetUrl, application, componentForm } = {
    ...params,
  };
  try {
    const analyticsClickEvent = createAnalyticsClickEvent(
      event,
      application ?? getApplication(),
      {
        clickElementId,
        targetUrl,
      },
      componentForm ?? getComponentForm(),
    );

    trackEvent(analyticsClickEvent);
  } catch (e: any) {
    console.log("Failed to send analytics event", e);
  }
};

export const useClickAnalytics = (event: IAnalyticsClickEventTemplate) => {
  return useCallback(
    (clickElementId: string, targetUrl?: string, componentForm?: any) => {
      trackClickEvent(event, { clickElementId, targetUrl, componentForm });
    },
    [event],
  );
};
